import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import s from "../css/Homepage.module.css";
import { connect } from "react-redux";
import { uzLanguege } from "../redux/Actions/uzLanguege";
import { ruLanguege } from "../redux/Actions/ruLanguege";
import { enLanguege } from "../redux/Actions/enLanguege";
import axios from "axios";
import { host, host1, hosten, hostru } from "../config/host";

import Elon from "./Elon";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Table } from "react-bootstrap";

class Videolar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      data: [],
      perPage: 8,
      currentPage: 0,
      loader: true,
      color: "#218C87",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  override = css`
    display: block;
    margin: 0 auto;
    border-color: aqua;
  `;
  receivedData() {
    axios
      .get(
        this.props.uzLang
          ? `${host}/videos/`
          : this.props.enLang
            ? `${hosten}/videos/`
            : `${hostru}/videos/`
      )
      .then((res) => {
        this.setState({
          loader: false,
          color: "#218C87",
        });
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        const postData = slice.map((item, uzLang, enLang) => {
          return item.file != null ? (
            <tr className="">
              <td>
                {" "}
                <span className="">{item.subject_name}</span>
              </td>
              <td>
                <a className="mx-2" download href={item.file} target='_blank'>
                  {item.name}
                </a>
              </td>
              <td>
                <a> </a>
              </td>
            </tr>
          ) : (
            <tr className="">
              <td>
                {" "}
                <span>{item.subject_name}</span>
              </td>
              <td>
                <a>{item.name}</a>
              </td>
              <td>
                <a download href={item.link} target='_blank'>
                  {this.props.uzLang
                    ? "Ochish"
                    : this.props.enLang
                      ? "Link"
                      : "Открыть"}{" "}
                </a>
              </td>
            </tr>
          );
        });
        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          postData,
        });
      });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
  };

  componentDidMount() {
    this.receivedData(this.props.uzLang, this.props.enLang);
  }
  render() {
    const { uzLang, enLang } = this.props;
    const { loader, color } = this.state;
    return (
      <>
        <div className="container">
          <div className="row my-5">
            {/* <div className="col-lg-1"></div> */}
            {loader ? (
              <div className="d-flex justify-content-center align-itmes-center">
                <div className="sweet-loading">
                  {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
        <input value={color} onChange={(input) => setColor(input.target.value)} placeholder="Color of the loader" /> */}

                  <ScaleLoader
                    color={color}
                    loading={loader}
                    css={this.override}
                    size={100}
                  />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-8 overflow-hidden">
                  <h4 className={s.izoh}>
                    {" "}
                    <i className="fa fa-file-video"></i>{" "}
                    {uzLang
                      ? "Videolar ro`yxati"
                      : enLang
                        ? "List of Videos"
                        : "Список видеоуроков"}
                  </h4>
                  <div>
                    <Table
                      striped
                      bordered
                      hover
                      size="xl"
                      text="center"
                      className="tables"
                    >
                      <thead>
                        <tr>
                          <th>
                            {uzLang ? "Fan" : enLang ? "Subject" : "Предмет"}
                          </th>
                          <th>
                            {uzLang ? "Video" : enLang ? "Video" : "Видео"}
                          </th>
                          <th>
                            {uzLang ? "Havola" : enLang ? "View" : "Открыть"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.state.postData}</tbody>
                    </Table>
                  </div>
                  <div className="d-flex w-100% paginates">
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      className={s.pagination}
                    />
                  </div>
                </div>
                <div
                  className="col-lg-4 "
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                >
                  <Elon />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uzLang: state.changeLang.uzLang,
    enLang: state.changeLang.enLang,
  };
};

export default connect(mapStateToProps, { uzLanguege, ruLanguege, enLanguege })(
  Videolar
);
